import { defineAsyncComponent } from 'vue'
import VueRouter from 'vue-router';
const HomePage = defineAsyncComponent(() => import('../views/homepage.vue'))

const router = new VueRouter({
    mode: "history",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            component: HomePage
        }
    ]
});




export default router;